<template>
  <div class="financedetails">
    <div class="title">
      <p class="c-box">
        <i class="el-icon-house" />
        <span @click="goto('/commodity')">首页</span> > <span class="">财务管理</span>
      </p>
    </div>
    <div class="c-box">
      <div class="infotit">
        <div><span class="dian">●</span>账单号：<span>{{ data.billCode }}</span></div>
        <div><span class="dian">●</span>订单号：<span>{{ data.orderNo }}</span></div>
        <div><el-button @click="downloadBills">下载账单</el-button></div>
      </div>
      <div class="content tablediv">
        <el-table :data="tableData" class="freightRate-table" :span-method="objectSpanMethod" style="width: 100%">
          <el-table-column prop="billCode" label="账单编号" align="center" />
          <el-table-column prop="createTime" label="账单日期" align="center" />
          <el-table-column prop="costName" label="费用名称" align="center" />
          <el-table-column prop="amount" label="金额" align="center" />
          <el-table-column prop="currencyName" label="币种" align="center" />
          <el-table-column prop="remarks" label="备注" align="center" />
        </el-table>
        <div class="heji">
          <span class="tit">合计：</span>
          <div class="Hspan">
            <p v-for="(item,i) in data.billAmountList" :key="i">{{ item }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { lookDetail } from '@/api/finance.js';
import { downloadBills } from '@/api/order.js';
export default {
  name: 'Financedetails',
  props: {

  },
  data: function() {
    return {
      data: {},
      tableData: []
    };
  },

  computed: {

  },
  watch: {

  },
  created() {
    this.init();
  },
  methods: {
    init() {
      const id = this.$route.query.id;
      if (id) {
        this.lookDetail();
      } else {
        this.$router.push({ path: '/financelist' });
      }
    },
    // 请求数据
    lookDetail() {
      const id = this.$route.query.id;
      lookDetail({ id }).then(result => {
        console.log(result.data);
        if (result.data.code == 0) {
          const data = result.data.data;
          this.data = data;
          this.tableData = data.receivableBillDetailVOS;
          for (const i in this.tableData) {
            this.tableData[i].billCode = data.billCode;
            this.tableData[i].createTime = data.createTime;
          }
        } else {
          this.$message({ message: result.data.msg, type: 'warning' });
        }
      }).catch(() => {
        this.$message({ message: '请求失败', type: 'warning' });
      });
    },
    // 合并行 当前行row、当前列column、当前行号rowIndex、当前列号 columnIndex
    objectSpanMethod({ rowIndex, columnIndex }) {
      const rowLen = this.tableData.length;
      if (columnIndex == 0 || columnIndex == 1) {
        if (rowIndex == 0) {
          return {
            rowspan: rowLen,
            colspan: 1
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0
          };
        }
      }
    },
    goto(route) {
      this.$router.push({ path: route });
    },
    downloadBills() {
      window.location.href = downloadBills(+this.$route.query.id);
    }
  }
};

</script>

<style scoped>
/*标题*/
.title{height:30px;line-height:30px;background:#F0F0F0;margin:0;font-size:12px;color:#999999}
.title i{margin-right:10px;}
.title span{cursor:pointer;}
.title span:nth-child(3){color:#333}

.infotit{width: 100%;display: flex;}
.infotit div{width:30%;line-height:80px;font-size:16px;font-family:Microsoft YaHei;font-weight:bold;color:#333333;}
.infotit .dian {margin-right: 6px;color: #507eff;}
/*表格*/
.tablediv {width: 100%;border: 1px solid #eeeeee;border-radius:10px}
.heji {padding-right: 20px;text-align: right;min-height: 45px;padding-top: 10px}
.heji .tit {color: #333333;font-weight: bold;}
.Hspan{display: inline-table;}
.Hspan>p{color: red;}
</style>
